import React, { useState, useEffect } from "react"
import { Box, Heading } from "@chakra-ui/core"

type Props = { headings: { level: number; value: string }[] }

export const TableOfContent: React.FC<Props> = ({ headings }) => {
  const titles = headings.map((h, i: any) => {
    return <Box>{h.value}</Box>
  })
  return (
    <Box display="flex" flexDirection="column">
      <Heading>Sisällysluettelo</Heading>
      {titles}
    </Box>
  )
}
