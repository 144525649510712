import { Link } from "@chakra-ui/core"
import React from "react"
import { trackClick } from "../../utils/tracking/trackingHelp"

type Props = { link?: string; label?: string; category?: string }

export const BlogAffiliatelink: React.FC<Props> = ({
  link,
  label,
  category,
}) => {
  return (
    <Link
      target="_blank"
      href={link}
      onClick={() => trackClick(label, category, "kylpylaKlikki")}
    >
      Tutustu ja varaa
    </Link>
  )
}
